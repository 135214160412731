import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import InnerHeader from "../../components/global/page-partials/inner-header-1/InnerHeader"
import * as searchStyles from "./search.module.scss"
import { SearchStateContext } from "../../context/SearchContext"

const SearchPage = ({ pageContext, location }) => {
  const { searchTerm, results, setSearch, loading } =
    //pull searchTerm from context
    React.useContext(SearchStateContext)

  // call set search once the component mounts
  useEffect(() => {
    setSearch()
  }, [searchTerm])

  return (
    <>
      <Layout>
        <InnerHeader
          pageTitle="Search"
          pageContext={pageContext}
          location={location}
        />
        <div
          className="inner-container-alt inner-y-padding"
          style={{ minHeight: `400px` }}
        >
          <h2>Search Results</h2>
          {searchTerm ? (
            <p style={{ fontSize: `1.6rem` }}>
              for "
              <em style={{ color: "#9d5918" }}>
                <strong>{searchTerm}</strong>
              </em>
              "
            </p>
          ) : (
            <p style={{ fontSize: `1.6rem` }}>
              <em>Please Enter a Search term</em>
            </p>
          )}
          {/* search results */}

          {searchTerm ? (
            <div
              className={searchStyles.searchResults}
              style={{ marginTop: `2rem` }}
            >
              {loading ? (
                <p>loading...</p>
              ) : results.length > 0 ? (
                results.map((result, index) => (
                  <ul
                    key={index}
                    style={{ maxWidth: `55rem`, marginBottom: `2rem` }}
                    role="group"
                  >
                    <li aria-label="Search Hit">
                      <Link
                        to={result.document.page_path}
                        title={result.document.title}
                      >
                        <h2 className="h5">{result.document.title}</h2>
                      </Link>
                      <p>{result.document.description}</p>
                    </li>
                  </ul>
                ))
              ) : (
                <p>no results for "{searchTerm}"</p>
              )}
            </div>
          ) : null}
        </div>
      </Layout>
    </>
  )
}

export default SearchPage
